<template>
  <form @submit.prevent="submitPicks">
    <div v-if="gamesData.type !== 'basketball'" class="guess-box">
      <div class="guess-score" v-html="gamesModulesContent.trGuessScoreLabel"></div>
      <div class="picks-btn">
        <button @click.prevent="randomScore" class="button button--blue">{{ gamesModulesContent.trGuessScoreRandomBtn }}</button>
      </div>
    </div>
    <div v-if="gamesData.games">
      <div v-for="(game, index) in gamesData.games" :key="index" class="match">
        <div class="match-date">{{ filterTimeFormat(game.date) }}</div>
        <div class="match-header">
          <div class="team team-home">{{ game.homeTeam.name }}</div>
          <div class="match-header-divider">V</div>
          <div class="team team-away">{{ game.awayTeam.name }}</div>
        </div>
        <div class="match-options">
          <div class="pick-number">{{ index + 1 }}</div>
          <div class="match-teams">
            <div class="single-team">
              <div v-if="gamesData.hideLogos === false" class="correct-score-team">
                <img :src="game.homeTeam.logo" :alt="game.homeTeam.name">
              </div>
              <div class="correct-score-buttons">
                <button @click.prevent="updateScore('plus', index, 'homeTeam')" class="score-btn">+</button>
                <input class="input input--round" v-model.number="game.homeTeam.score" type="text" @keypress="onlyNumber" @keyup="limitMaxScore(index, 'homeTeam')">
                <button @click.prevent="updateScore('minus', index, 'homeTeam')" class="score-btn">-</button>
              </div>
            </div>
            <div class="correct-score-divider">-</div>
            <div class="single-team away-team">
              <div v-if="gamesData.hideLogos === false" class="correct-score-team">
                <img :src="game.awayTeam.logo" :alt="game.awayTeam.name">
              </div>
              <div class="correct-score-buttons">
                <button @click.prevent="updateScore('plus', index, 'awayTeam')" class="score-btn">+</button>
                <input class="input input--round" v-model.number="game.awayTeam.score" type="text" @keypress="onlyNumber" @keyup="limitMaxScore(index, 'awayTeam')">
                <button @click.prevent="updateScore('minus', index, 'awayTeam')" class="score-btn">-</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cta">
      <button type="submit" class="button button--main">{{ gamesModulesContent.trSubmitButton }}</button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'Games',
  computed: {
    ...mapGetters([
      'gamesModulesContent',
      'routePath',
    ]),
    sumbitPageUrl() {
      return `${this.routePath.sportTypeSlug}/${this.routePath.currentGeo}/submit`;
    },
  },
  props: {
    games: Object,
  },
  data() {
    return {
      gamesData: {},
    };
  },
  created() {
    this.gamesData = { ...this.games };
    moment.locale(this.gamesModulesContent.localeCode);
  },
  methods: {
    ...mapActions([
      'saveUserPicks',
    ]),
    submitPicks() {
      window.dataLayer.push({
        event: 'Form step',
        stepNo: 'step 2',
        stepName: 'Insert your answers',
      });
      const { competitionId } = this.gamesData.games[0];
      const userPicks = {
        picks: [],
        competitionId,
      };
      this.gamesData.games.forEach((game) => {
        userPicks.picks.push({
          home: {
            teamId: game.homeTeam.id,
            score: game.homeTeam.score > 9999 ? 9999 : game.homeTeam.score,
          },
          away: {
            teamId: game.awayTeam.id,
            score: game.awayTeam.score > 9999 ? 9999 : game.awayTeam.score,
          },
        });
      });
      // Conversion userPicks JS object to a JSON string
      const userPicksString = JSON.stringify(userPicks);
      // Save data to sessionStorage
      sessionStorage.setItem('userPicks', userPicksString);
      this.saveUserPicks(userPicks);
      // Pushing to Submit View
      this.$router.push({ path: this.sumbitPageUrl, query: { ...this.$route.query } });
    },
    updateScore(direction, gameIndex, teamSide) {
      if (direction === 'minus' && this.gamesData.games[gameIndex][teamSide].score === 0) return;
      if (direction === 'plus' && this.gamesData.games[gameIndex][teamSide].score >= 15 && this.gamesData.type !== 'basketball') return;
      if (direction === 'minus') this.gamesData.games[gameIndex][teamSide].score -= 1;
      if (direction === 'plus') this.gamesData.games[gameIndex][teamSide].score += 1;
    },
    randomScore() {
      for (let index = 0; index < this.gamesData.games.length; index += 1) {
        this.gamesData.games[index].homeTeam.score = Math.floor(Math.random() * 5);
        this.gamesData.games[index].awayTeam.score = Math.floor(Math.random() * 5);
      }
    },
    filterTimeFormat(time) {
      return moment(time * 1000).format('dddd, MMMM Do YYYY, h:mm:ss a');
    },
    // Allow only numbers in input
    onlyNumber(event) {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      console.log(event);
      if ((keyCode < 48 || keyCode > 57)) {
        event.preventDefault();
      }
    },
    limitMaxScore(gameIndex, teamSide) {
      if (this.gamesData.games[gameIndex][teamSide].score <= 250) return;
      this.gamesData.games[gameIndex][teamSide].score = 250;
    },
  },
};
</script>
