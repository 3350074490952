<template>
  <form @submit.prevent="submitPicks">
    <div v-if="gamesData.games">
      <div v-for="(game, index) in gamesData.games" :key="index" class="game-row">
        <div v-if="game.id === 'cricket-which-team'">
          <div class="teams-row">
            <div class="match-date">{{ filterTimeFormat(game.gameStart) }}</div>
            <div class="match-header">
              <div class="team team-home">{{ game.homeTeam.name }}</div>
              <div class="match-header-divider">V</div>
              <div class="team team-away">{{ game.awayTeam.name }}</div>
            </div>
            <div class="match-options">
              <div class="question">
                {{ game.question }}
              </div>
              <div class="match-teams" :class="{ logoless: gamesData.hideLogos === true }">
                <div class="single-team">
                  <div v-if="gamesData.hideLogos === false" class="correct-score-team">
                    <img :src="game.homeTeam.logo" :alt="game.homeTeam.name">
                  </div>
                  <div class="correct-score-buttons">
                    <label>
                      <input class="radio-input" type="radio" :value="game.homeTeam.id" v-model="game.answer">
                      <div class="input-mask"></div>
                    </label>
                  </div>
                </div>
                <div class="correct-score-divider">-</div>
                <div class="single-team away-team">
                  <div v-if="gamesData.hideLogos === false" class="correct-score-team">
                    <img :src="game.awayTeam.logo" :alt="game.awayTeam.name">
                  </div>
                  <div class="correct-score-buttons">
                    <label>
                      <input class="radio-input" type="radio" :value="game.awayTeam.id" v-model="game.answer">
                      <div class="input-mask"></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="question-box" v-else-if="game.id !== 'cricket-which-team'">
          <div class="question">
            {{game.question}}
          </div>
          <div class="answer">
            <button @click.prevent="updateAnswer('plus', index)" class="score-btn">+</button>
            <input class="input input--round" v-model.number="game.answer" type="text" @keypress="onlyNumber">
            <button @click.prevent="updateAnswer('minus', index)" class="score-btn">-</button>
          </div>
        </div>
      </div>
    </div>
    <div class="cta">
      <button :class="{ disabled: isGameSelected !== undefind }" type="submit" class="button button--main">{{ gamesModulesContent.trSubmitButton }}</button>
    </div>
  </form>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'QuizFormat',
  computed: {
    ...mapGetters([
      'gamesModulesContent',
      'routePath',
    ]),
    submitPagePath() {
      return `${this.routePath.sportTypeSlug}/${this.routePath.currentGeo}/submit`;
    },
    isGameSelected() {
      return this.gamesData.games.find((element) => element.id === 'cricket-which-team' && element.answer === 0);
    },
  },
  props: {
    games: Object,
  },
  data() {
    return {
      gamesData: {},
    };
  },
  created() {
    this.gamesData = { ...this.games };
    moment.locale(this.gamesModulesContent.localeCode);
    console.log(this.gamesData.games);
  },
  methods: {
    ...mapActions([
      'saveUserPicks',
    ]),
    submitPicks() {
      if (this.isGameSelected !== undefined) return;
      window.dataLayer.push({
        event: 'Form step',
        stepNo: 'step 2',
        stepName: 'Insert your answers',
      });
      const { competitionId } = this.gamesData.games[0];
      const userPicks = {
        picks: [],
        competitionId,
      };
      this.gamesData.games.forEach((game) => {
        userPicks.picks.push({
          answer: game.answer > 9999 ? 9999 : game.answer,
          questionId: game.id,
        });
      });
      // Conversion userPicks JS object to a JSON string
      const userPicksString = JSON.stringify(userPicks);
      // Save data to sessionStorage
      sessionStorage.setItem('userPicks', userPicksString);
      this.saveUserPicks(userPicks);
      // Pushing to Submit View
      this.$router.push({ path: this.submitPagePath, query: { ...this.$route.query } });
    },
    updateAnswer(direction, gameIndex) {
      if (direction === 'minus' && this.gamesData.games[gameIndex].answer === 0) return;
      if (direction === 'plus' && this.gamesData.games[gameIndex].answer >= 9999) return;
      if (direction === 'minus') this.gamesData.games[gameIndex].answer -= 1;
      if (direction === 'plus') this.gamesData.games[gameIndex].answer += 1;
    },
    filterTimeFormat(time) {
      return moment(time * 1000).format('dddd, MMMM Do YYYY, h:mm:ss a');
    },
    // Allow only numbers in input
    onlyNumber(event) {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      if ((keyCode < 48 || keyCode > 57)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
// Quiz Format Styles
.question-box {
  padding: .5rem;
  text-align: center;
  margin-top: .5rem;
}
.question {
  text-align: center;
  color: #4a4a4a;
}
.question-box input {
  margin: 5px;
}
.answer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: .5rem;
}
.game-row {
  position: relative;
}
.game-row:not(:last-child)::after {
  content: '';
  height: 1px;
  background: linear-gradient( 90deg ,#0058ff 0,#bb44ea 50%,#fd7c00);
  display: block;
  width: 100%;
}
.radio-input {
  display: none;
  &+ .input-mask {
    height: 30px;
    width: 30px;
    display: inline-block;
    cursor: pointer;
    vertical-align: middle;
    background: #FFF;
    border: 3px solid #fff;
    border-radius: 100%;
    box-shadow: 0px 0px 0px 1px #cfcfcf;
    transition: .3s ease-in-out all;
    &:hover {
      background: #32aa45;
    }
  }
  &:checked + .input-mask {
    background:#32aa45;
  }
}
.disabled {
  opacity: .5;
  cursor: not-allowed;
  outline: none;
}
</style>
